import { useEffect, useMemo } from 'react';
import { Translations } from '../../../core/types';
import { selectCurrentLanguage } from '../../../core/uiLanguage/lang.slice';
import { COMMUNICATION_ICON_MAPPING, COMMUNICATION_STATUS_ICON_MAPPING, ICONS, ID_MAIN_CONTRACT, USER_CUSTOMER, USER_PARTNER, USER_WHOLESALER } from '../../constants/configs/config.constants';
import { useSelectAddresses, useSelectApiCallStatus, useSelectContracts, useSelectCountries, useSelectFraudLock, useSelectLang, useSelectNumbers, useSelectPartnerCustomerContract, useSelectUserRole, } from '../../redux/store';
import { Languages } from '../../../core/uiLanguage/types';
import { TRANSLATIONS } from '../../constants/transitions/uiTranslations';
import { ReactComponent as Mail } from '../../../portal/assets/icons/mail.svg';
import { ReactComponent as Phone } from '../../../portal/assets/icons/phone_blue.svg';
import { ReactComponent as ContractIcon } from '../../../portal/assets/icons/contract.svg';
import { ReactComponent as Refresh } from '../../../portal/assets/icons/refresh.svg';
import { CpxIcon } from "../../../core/components/icon.component";
import cs from 'classnames';
import '../../../App.scss';
import { IAddresses, IContracts, INfonCliInformations } from "../../constants/types/types.constants";
import { BasketEntry, PortalContractData } from "compax-api";
import { useTheme } from "../../../core/utility/themeContext";
import { apiCallAction } from "../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../constants/action.constants";
import { useDispatch } from "react-redux";

export const useTranslations = <T extends Translations<Languages>>(
  translationsModule: T
) => {
  const lang = selectCurrentLanguage(useSelectLang());

  return useMemo(() => {
    const translations = {} as {
      [Key in keyof typeof translationsModule]: (...args: string[]) => string;
    };

    Object.entries(translationsModule).forEach(([key, value]) => {
      translations[key as keyof typeof translationsModule] = (
        ...args: string[]
      ) => value(...args)[lang];
    });

    return translations;
  }, [lang, translationsModule]);
};

export const useGetAmountOfNumbers = () => {
  const numbers = useSelectNumbers() as INfonCliInformations;
  return numbers && numbers.reduce((prev, cur) => prev + (cur.numberCount || 0), 0);
}
export const useGetSites = () => {
  return useSelectAddresses() as IAddresses
}

export const useGetAmountOfSites = () => useGetSites()?.length;

export const useGetSipTrunk: () => PortalContractData | undefined = () => {
  const myContracts = useSelectContracts() as IContracts;
  return Array.isArray(myContracts) ? myContracts.find((contract) => contract?.serviceType?.id + '' === ID_MAIN_CONTRACT) : undefined;
};

export const useGetSipTrunkId = () => useGetSipTrunk()?.id;

export const useGetLocked: () => any = () => {
  const dispatch = useDispatch();
  const fraudLocks = useSelectFraudLock();
  const isLoggedIn = useSelectApiCallStatus().loggedIn;
  const role = useSelectUserRole();

  useEffect(() => {
    if (isLoggedIn && role) {
      role === USER_CUSTOMER && dispatch(apiCallAction(ACTION_CONST.API_GET_FRAUDLOCK_OF_CONTRACT));
      role === USER_CUSTOMER && dispatch(apiCallAction(ACTION_CONST.API_GET_MAIN_CONTRACTS, {selectedPartnerCustomerId: undefined}, true))
    }
  }, [dispatch, isLoggedIn, role]);

  return (!isLoggedIn || fraudLocks.length === 0)
    ? false :
    fraudLocks;
};

export const useGetTitle = () => {
  const translations = useTranslations(TRANSLATIONS.header);
  const userRole = useSelectUserRole();
  const theme = useTheme();

  if (userRole === USER_PARTNER) {
    if (theme === 'dts') {
      return translations.dtsPartnerTitle();
    } else {
      return translations.partnerTitle();
    }
  } else if (userRole === USER_CUSTOMER) {
    return translations.customerTitle();
  } else if (userRole === USER_WHOLESALER) {
    return translations.wholesalerTitle();
  } else {
    return translations.title();
  }
};

export const getCommunicationIcon = (communicationType: any, theme: any) => {
  let icon;

  switch (communicationType) {
    case COMMUNICATION_ICON_MAPPING.MAIL:
      icon = (
        <div className={cs('iconClass', `iconClass-le--${theme}`)}>
          <Mail/>
        </div>
      );
      break;
    case COMMUNICATION_ICON_MAPPING.TELEFON:
      icon = (
        <div className={cs('iconClass', `iconClass-le--${theme}`)}>
          <Phone/>
        </div>
      );
      break;

    case COMMUNICATION_ICON_MAPPING.POST:
      icon = (
        <div className={cs('iconClass', `iconClass-le--${theme}`)}>
          <ContractIcon/>
        </div>
      );
      break;

    default:
      icon = (
        <div className={cs('iconClass', `iconClass-le--${theme}`)}>
          <Mail/>
        </div>
      );
      break;
  }
  return icon;
};

export const getCommunicationStatusIcon = (
  communicationStatusType: any,
  theme: any
) => {
  let icon;
  switch (communicationStatusType) {
    case COMMUNICATION_STATUS_ICON_MAPPING.OK:
      icon = (
        <div>
          <CpxIcon icon={ICONS.STATUS.ACTIVE}/>
        </div>
      );
      break;

    case COMMUNICATION_STATUS_ICON_MAPPING.OPEN:
      icon = (
        <div className={cs('statusIconClass', `statusIconClass-le--${theme}`)}>
          <Refresh/>
        </div>
      );
      break;

    default:
      icon = (
        <div className={cs('statusIconClass', `statusIconClass-le--${theme}`)}>
          <Refresh/>
        </div>
      );
      break;
  }
  return icon;
};

export const getCountryById = (id: number) => {
  const countries = useSelectCountries();
  return countries?.filter((country: any) => country.id === id)[0]?.description;
}


export const getMainContract = () => {
  const contracts: IContracts = useSelectContracts();
  return contracts && Array.isArray(contracts) ?
    contracts.filter(contract => contract.serviceType?.id + '' === ID_MAIN_CONTRACT)[0] : {};
}

export const getMainContractOfCustomerForPartner = () => {
  const contracts: any = useSelectPartnerCustomerContract();
  return contracts && Array.isArray(contracts) ?
    contracts.filter(contract => contract.serviceType?.id + '' === ID_MAIN_CONTRACT)[0] : {}
}

export function trimStringProperties(obj: any) {
  for (let prop in obj) {
    if (typeof obj[prop] === 'string') {
      obj[prop] = obj[prop].trimEnd();
    } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      trimStringProperties(obj[prop]);
    }
  }
}

export const sortTable = (cell: any, dir: string, rowData: any) => {
  let sortedData;
  if (cell.sortMethod === 'string') {
    sortedData = rowData.sort((a: any, b: any) => {

      //if object in object
      if (cell.sortBy.includes('.')) {
        const [parent, child] = cell.sortBy.split('.');
        return dir === "asc"
          ? ("" + a[parent][child]).localeCompare(b[parent][child])
          : ("" + b[parent][child]).localeCompare(a[parent][child])
      }

      return dir === "asc"
        ? ("" + a[cell.sortBy]).localeCompare(b[cell.sortBy])
        : ("" + b[cell.sortBy]).localeCompare(a[cell.sortBy])
    });

  } else if (cell.sortMethod === 'number') {
    sortedData = rowData.sort((a: any, b: any) => {

      //if object in object
      if (cell.sortBy.includes('.')) {
        const [parent, child] = cell.sortBy.split('.');
        return dir === "asc"
          ? a[parent][child] - b[parent][child]
          : b[parent][child] - a[parent][child]
      }

      return dir === "asc"
        ? a[cell.sortBy] - b[cell.sortBy]
        : b[cell.sortBy] - a[cell.sortBy]
    })

  } else if (cell.sortMethod === 'date') {
    sortedData = rowData.sort((a: any, b: any) => {

      //if object in object
      if (cell.sortBy.includes('.')) {
        const [parent, child] = cell.sortBy.split('.');
        if (a[parent][child]?.includes('.') || b[parent][child]?.includes('.')) {
          let [day, month, year] = a[parent][child].slice(0, 10).split('.');
          let time = a[parent][child].includes(' ') ? a[parent][child]?.split(' ')[1] : "00:00";
          let [hour, minute] = time?.split(':')
          const dateA = Date.parse(new Date(+year, +month - 1, +day, +hour, +minute).toString());
          [day, month, year] = b[parent][child].slice(0, 10).split('.');
          time = b[parent][child].includes(' ') ? a[parent][child]?.split(' ')[1] : "00:00";
          [hour, minute] = time?.split(':')
          const dateB = Date.parse(new Date(+year, +month - 1, +day, +hour, +minute).toString());
          return dir === "asc" ? dateA - dateB : dateB - dateA;
        } else if (new Date(a[parent][child] && new Date(b[parent][child]))) {
          return dir === "asc"
            ? Date.parse(new Date(a[parent][child]).toString()) - Date.parse(new Date(b[parent][child]).toString())
            : Date.parse(new Date(b[parent][child]).toString()) - Date.parse(new Date(a[parent][child]).toString())
        }
      }

      if (a[cell.sortBy]?.includes('.') || b[cell.sortBy]?.includes('.')) {
        //if string is not date format
        let [day, month, year] = a[cell.sortBy].slice(0, 10).split('.');
        let time = a[cell.sortBy].split(' ')[1];
        let [hour, minute] = time.split(':')
        const dateA = Date.parse(new Date(+year, +month - 1, +day, +hour, +minute).toString());
        [day, month, year] = b[cell.sortBy].slice(0, 10).split('.');
        time = b[cell.sortBy].split(' ')[1];
        [hour, minute] = time.split(':')
        const dateB = Date.parse(new Date(+year, +month - 1, +day, +hour, +minute).toString());
        return dir === "asc" ? dateA - dateB : dateB - dateA;

      } else if (new Date(a[cell.sortBy] && new Date(b[cell.sortBy]))) {
        return dir === "asc"
          ? Date.parse(new Date(a[cell.sortBy]).toString()) - Date.parse(new Date(b[cell.sortBy]).toString())
          : Date.parse(new Date(b[cell.sortBy]).toString()) - Date.parse(new Date(a[cell.sortBy]).toString())
      }
    })
  }
  return sortedData;
}

export const sortBasketEntries = (entry: BasketEntry) => {
  const getTotalCharges = (option: any) => {
    let totalCharges = 0;

    if (option.oneTimeCharges) {
      totalCharges += option.oneTimeCharges.reduce((total: any, charge: any) => total + charge.amount, 0);
    }

    if (option.recurringCharges) {
      totalCharges += option.recurringCharges.reduce((total: any, charge: any) => total + charge.amount, 0);
    }

    return totalCharges;
  };

  if (entry.options) {
    entry.options.sort((opt1: any, opt2: any) => {
      const opt1TotalCharges = getTotalCharges(opt1);
      const opt2TotalCharges = getTotalCharges(opt2);

      return opt1TotalCharges >= opt2TotalCharges ? -1 : 1;
    });
  }

  return entry;
};

const date = new Date();

export const endOfMonth: Date = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0,
  23,
  59
);