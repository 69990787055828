import React, { PropsWithoutRef, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { selectCurrentLanguage } from '../../../../core/uiLanguage/lang.slice';
import { useTheme } from '../../../../core/utility/themeContext';
import { routes } from '../../../constants/routes/routes';
import { useMenuItems, useSelectContracts, useSelectedMainContract, useSelectLang, useSelectPartnerCustomer, useSelectPartnerCustomerData, useSelectUser, useSelectUserRole, } from '../../../redux/store';
import { NfoNavLink } from './navLink.component';
import { useTranslations } from '../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import NfoPartnerCustomerSubmenu from './submenus/partnerCustomerSubmenu.component';
import { NfoPartnerCustomerSearch } from "./partnerCustomerSearch.component";
import { IContracts, IPartnerCustomer } from '../../../constants/types/types.constants';
import { ADMIN_PORTAL_URL, FEATURES_FLAG, ICONS, ID_MAIN_CONTRACT, PRODUCT_IDS, USER_CUSTOMER, USER_CUSTOMER_TELEPHONY, USER_PARTNER, USER_WHOLESALER } from "../../../constants/configs/config.constants";
import cs from 'classnames';
import './navigation.scss';
import { CpxIcon } from "../../../../core/components/icon.component";
import { PortalContractData } from "compax-api";
import { apiCallAction } from "../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../constants/action.constants";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { ReactComponent as Close } from '../../../assets/icons/close_white.svg';
import { unsetSelectedContract } from "../../../redux/actions/config.action";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

type Props = PropsWithoutRef<JSX.IntrinsicElements['button']>;

export const NfoNavigation = (props: Props) => {
  const [collapsed, setCollapsed] = useState(true);
  const translations = useTranslations(TRANSLATIONS.common);
  const lang = selectCurrentLanguage(useSelectLang());
  const theme = useTheme();
  const internalClassName = 'navigation';
  const role = useSelectUserRole();
  const dispatch: AppDispatch = useDispatch();

  const selectedPartnerCustomer: IPartnerCustomer = useSelectPartnerCustomer() as IPartnerCustomer;
  const adminPortalUrls: any[] = [];
  const selectedContract = useSelectedMainContract() as PortalContractData;
  const history = useHistory();


  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_PORTAL_FEATURE, { featureName: FEATURES_FLAG.PORTAL_PARTNER_CUSTOMER_ADMINSTRATION_MENU }))
  }, []);

  useEffect(() => {

    if ((role === USER_PARTNER || role === USER_WHOLESALER) && selectedPartnerCustomer?.id && selectedContract?.id) {
      dispatch(apiCallAction(ACTION_CONST.API_GET_CUSTOMERS_MENU_ITEMS, { selectedPartnerCustomerId: selectedPartnerCustomer.id, contractId: selectedContract?.id }))
    }
  }, [selectedPartnerCustomer.id, selectedContract.id])

  useEffect(() => {

    if (role === USER_CUSTOMER && selectedContract?.id) {
      dispatch(apiCallAction(ACTION_CONST.API_GET_CUSTOMERS_MENU_ITEMS, { selectedPartnerCustomerId: undefined, contractId: selectedContract?.id }))
    }
  }, [selectedContract.id])

  const findExternalURlsForPartner = () => {

    const selectedCustomer = useSelectPartnerCustomerData();


    let mainContract = selectedCustomer && selectedCustomer.contract && Array.isArray(selectedCustomer.contract) && selectedCustomer.contract.filter(
      entry =>
        entry.componentOf === undefined &&
        entry?.serviceType?.id + '' === ID_MAIN_CONTRACT
    )[0];

    findExternalURlsByMainContractAndLegalEntity(mainContract, theme);
  }



  const findExternalURlsForCustomer = () => {

    const theCustomerContracts: IContracts = useSelectContracts();
    const theUser = useSelectUser();

    let mainContract = theCustomerContracts && theCustomerContracts.filter(
      entry =>
        entry.componentOf === undefined &&
        entry?.serviceType?.id + '' === ID_MAIN_CONTRACT
    )[0];

    findExternalURlsByMainContractAndLegalEntity(mainContract, theUser?.legalEntity?.id === 7 ? 'dts': 'nfon');
  }

  const findExternalURlsByMainContractAndLegalEntity = (mainContract: PortalContractData, legalEntity: string) => {


    if (legalEntity === 'nfon') {

      if (mainContract && mainContract.product?.id === PRODUCT_IDS.NCONNECT) {

        adminPortalUrls.push({label: translations.adminPortalConfig(), url: ADMIN_PORTAL_URL.NFON.N_CONNECT_VOICE});

      } else if (mainContract && mainContract.product?.id === PRODUCT_IDS.CLOUDYA) {

        adminPortalUrls.push({label: translations.adminPortalTitle(), url: ADMIN_PORTAL_URL.NFON.DEFAULT});
        adminPortalUrls.push({label: translations.adminPortalConfigCloudyaUser(), url: ADMIN_PORTAL_URL.NFON.CLOUDYA});

      }  else {
        adminPortalUrls.push({label: translations.adminPortalConfig(), url: ADMIN_PORTAL_URL.NFON.N_CONNECT_VOICE});
      }


    }
    if (legalEntity === 'dts') {

      if (mainContract && mainContract.product?.id === PRODUCT_IDS.SIP_TRUNK_FLEXX_DTS) {

        adminPortalUrls.push({label: translations.adminPortalConfig(), url: ADMIN_PORTAL_URL.DTS.CENTREXX_SIP});

      } else if (mainContract && mainContract.product?.id === PRODUCT_IDS.CENTREXX){

        adminPortalUrls.push({label: translations.adminPortalTitle(), url: ADMIN_PORTAL_URL.DTS.DEFAULT});
        adminPortalUrls.push({label: translations.adminPortalConfigCentrexxUser(), url:ADMIN_PORTAL_URL.DTS.CENTREXX});

      } else {

        adminPortalUrls.push({label:translations.adminPortalTitle(), url: ADMIN_PORTAL_URL.DTS.DEFAULT});

      }

    }
  }

  if (role === 'partner') {
    findExternalURlsForPartner();
  } else {
    findExternalURlsForCustomer();
  }

  let userNavigation = useMenuItems();

  Array.isArray(userNavigation) && userNavigation.map((item: any) => {

    switch (item.componentName) {
      case 'Standorte':
        item.routeInfo = routes.customerSites;
        break;
      case 'Rufnummern':
        item.routeInfo = routes.customerNumbers;
        break;
      case 'SIP-Trunk':
        item.routeInfo = routes.customerSIPTrunk;
        break;
      case 'Minutenpakete':
        item.routeInfo = routes.customerPackages;
        break;
      case 'Flatrate':
        item.routeInfo = routes.customerFlatrate;
        break;
      case 'Lizenzen & Services':
        item.routeInfo = routes.customerLicenses;
        break;
      case "Nebenstellen":
        item.routeInfo = routes.customerExtensions;
        break;
      case 'Security-Einstellungen':
        item.routeInfo = routes.customerSecuritySettings;
        break;
    }

    item.routeInfo.navTitle = () => ({
      DE: item.menuItemName.find((o: any) => o.language === 'de')?.value,
      EN: item.menuItemName.find((o: any) => o.language === 'en')?.value
    })
  })

  const uniqueMenuItems: any = [];

  const componentNameSet = new Set();

  Array.isArray(userNavigation) && userNavigation?.forEach((item : any) => {
    if (!componentNameSet.has(item?.componentName)) {
      componentNameSet.add(item?.componentName);
      //this is because the partner should not change the fraud and sec for a customer................
      if (item?.componentName !== 'Standorte') {
        uniqueMenuItems.push(item);
      }
    }
  });

  return (
    <nav {...props} className={cs(props.className, internalClassName, `${internalClassName}-le--${theme}`)}>
      <ul>
        {role === USER_CUSTOMER_TELEPHONY && (
          <>
            <li className={cs(`nav-li-le--${theme}`)} key={routes.customerMyData.path}>
              <NavLink
                id={'nav-link-my-contract'}
                to={routes.customerMyData.path}
                activeClassName={cs('active', `active-le--${theme}`)}
                exact={true}
                className={'nav-link-parent'}
              >
                <CpxIcon icon={ICONS.CONTRACT}/>
                <h5>
                  {!!routes.customerMyData.navTitle &&
                  routes.customerMyData.navTitle()[lang]}
                </h5>
              </NavLink>
              <ul>
                <NfoNavLink {...routes.customerBills} activeClass={'active'}/>
                <NfoNavLink {...routes.customerItemized} activeClass={'active'} key={routes.customerItemized.path}/>
                <NfoNavLink {...routes.customerSites} activeClass={'active'}/>
              </ul>
            </li>

          </>
        )}
        {role === USER_CUSTOMER && (
          <>
            <li className={cs(`nav-li-le--${theme}`)} key={routes.customerMyData.path}>
              <NavLink
                id={'nav-link-my-contract'}
                to={routes.customerMyData.path}
                activeClassName={cs('active', `active-le--${theme}`)}
                exact={true}
                className={'nav-link-parent'}
              >
                <CpxIcon icon={ICONS.CONTRACT}/>
                <h5>
                  {!!routes.customerMyData.navTitle &&
                  routes.customerMyData.navTitle()[lang]}
                </h5>
              </NavLink>
              <ul>
                <NfoNavLink {...routes.customerBills} activeClass={'active'}/>
                <NfoNavLink {...routes.customerItemized} activeClass={'active'} key={routes.customerItemized.path}/>
                <NfoNavLink {...routes.customerSites} activeClass={'active'}/>
              </ul>
            </li>
            <li className={cs(`nav-li-le--${theme}`)} key={routes.customerMyContract.path}>
              <NavLink
                id={'nav-link-my-contract'}
                to={routes.customerMyContracts.path}
                activeClassName={cs('active', `active-le--${theme}`)}
                exact={true}
                className={'nav-link-parent'}
              >
                <CpxIcon icon={ICONS.CONTRACT}/>
                <h5>
                  {!!routes.customerMyContracts.navTitle &&
                    routes.customerMyContracts.navTitle()[lang]}
                </h5>
              </NavLink>
            </li>

            {selectedContract?.id &&
              <li className={cs(`nav-li-le--${theme}`)} key={routes.customerSettings.path}>
                <div className={cs('firstLine', `firstLine-le--${theme}`)}>
                  <NavLink
                    id={'nav-link-my-contract'}
                    to={routes.customerMyContract.path}
                    activeClassName={cs('active', `active-le--${theme}`)}
                    exact={true}
                    className={'nav-link-parent'}
                  >
                    <CpxIcon icon={ICONS.CONTRACT}/>
                    <h5>{selectedContract.contractName + ' (' + selectedContract.knumber + ')'}</h5>
                  </NavLink>
                  <a className={'pointer'} onClick={() => {
                    dispatch(unsetSelectedContract());
                    history.push(routes.customerMyContracts.path)
                  }}>
                    <Close className={cs('iconDefault', `iconDefault-le--${theme}`)}/>
                  </a>
                </div>

                <ul>
                  <NfoNavLink
                    {...routes.customerContractDetails}
                    activeClass={'active'}/>
                  {Array.isArray(uniqueMenuItems) && uniqueMenuItems?.sort((a: any, b: any) => a.menuItemOrder - b.menuItemOrder).map((nav: any) => {
                    return (<NfoNavLink
                      {...nav.routeInfo} key={nav.routeInfo.path}
                      activeClass={'active'}
                    />)
                  })}
                </ul>
              </li>}
            {adminPortalUrls.map((item) =>
              <li className={cs(`nav-li-le--${theme}`)} key={'external'}>
                <NfoNavLink
                  id={'nav-link-adminportal'}
                  path={item.url}
                  target={'_blank'}
                  isExternalUrl={true}
                  internalClassname={'nav-link-parent'}
                >
                  <CpxIcon icon={ICONS.LINK}/>
                  <h5>{item.label}</h5>
                </NfoNavLink>
              </li>
            )}
          </>
        )}

        {(role === USER_PARTNER || role === USER_WHOLESALER) && (
          <>
            <li className={cs(`nav-li-le--${theme} active`)}>
              <NavLink
                id={'nav-link-partner-customers'}
                to={routes.partnerCustomers.path}
                className={'nav nav-link-parent'}
                activeClassName={cs('active', `active-le--${theme}`)}
                exact={false}
              >
                <div className={'navSearchButton'}>
                  <div className={'nav'}>
                    <CpxIcon icon={ICONS.CUSTOMER} className={'iconDefault'}/>
                    <h5>{!!routes.partnerCustomers.navTitle &&
                      routes.partnerCustomers.navTitle()[lang]}</h5>
                  </div>
                  <div className={'nav'}>
                    {/*<SearchCustomerIcon
                      onClick={() => setCollapsed(!collapsed)}
                      className={cs(
                        'partnerIcon',
                        'iconButton',
                        `partnerIcon-le--${theme}`
                      )}
                    />*/}
                    <CpxIcon
                      icon={ICONS.SEARCH} onClick={() => setCollapsed(!collapsed)}
                      className={cs(
                        'partnerIcon',
                        'iconButton',
                        `partnerIcon-le--${theme}`
                      )}
                    />
                    {!collapsed && <CpxIcon icon={ICONS.CROSS} className={cs('crossIcon', `crossIcon-le--${theme}`)}
                                            onClick={() => setCollapsed(!collapsed)}/>}
                  </div>
                </div>
              </NavLink>
              {!collapsed && <NfoPartnerCustomerSearch onClickOnResult={setCollapsed}/>}
            </li>
            {selectedPartnerCustomer.id && (
              <li>
                <NfoPartnerCustomerSubmenu
                  customerName={
                    selectedPartnerCustomer?.companyName ? selectedPartnerCustomer?.companyName : `${selectedPartnerCustomer?.firstName} ${selectedPartnerCustomer?.lastName}`
                  }
                />
              </li>
            )}
            <li className={cs(`nav-li-le--${theme}`)}>
              <NavLink
                id={'nav-link-partner-customers'}
                to={routes.partnerMyAccount.path}
                className={'nav nav-link-parent'}
                activeClassName={cs('active', `active-le--${theme}`)}
                exact={false}
              >
                <CpxIcon icon={ICONS.USER}/>
                <h5>{translations.myAccount()}</h5>
              </NavLink>
            </li>
            {adminPortalUrls.map((item) =>
              <li className={cs(`nav-li-le--${theme}`)}>
                <NfoNavLink
                  id={'nav-link-settings'}
                  path={item.url}
                  target={'_blank'}
                  isExternalUrl={true}
                  internalClassname={'nav-link-parent'}
                >
                  <CpxIcon icon={ICONS.LINK}/>
                  <h5>{item.label}</h5>
                </NfoNavLink>
              </li>
            )}
            {/* Sto1107: hidden because of not being implemented yet
            <li>
              <NavLink
                id={'nav-link-partner-tasks'}
                to={routes.partnerTasks.path}
                activeClassName={cs('active', `active-le--${theme}`)}
                exact={true}
              >
                <TasksIcon className={'iconDefault'}/>
                {!!routes.partnerCustomersTasks.navTitle &&
                routes.partnerCustomersTasks.navTitle()[lang]}
              </NavLink>
            </li>
            */}
          </>
        )}
      </ul>
    </nav>
  );
};
