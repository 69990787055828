import cs from 'classnames';
import React, { ForwardedRef, forwardRef, PropsWithoutRef, ReactNode, useEffect } from 'react';
import './phoneInputWithLabel.scss';
import { useTheme } from "../utility/themeContext";
import { InputError } from "./inputError.component";

type Props = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  children?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  required?: boolean;
  onPaste?: any;
  onChange?: any;
  fields?: any;
  errors?: any;
  touched?: any;
  label?: any;
  onGetPhoneNumber?: any;
  phoneType?: number;
  customItemName?: string;
};

export const CpxPhoneInputWithLabel = ({
                                          children,
                                          required,
                                          labelClassName,
                                          inputClassName,
                                          className,
                                          onPaste,
                                          fields,
                                          onChange,
                                          errors,
                                          touched,
                                          label,
                                          phoneType,
                                         customItemName,
                                          ...props
                                        }: Props): JSX.Element => {
  const theme = useTheme();

  const getPhoneInputError = (touched: any, errors: any) => {
    let errorMsg;
    if (touched.phone?.countryCode && errors?.phone?.countryCode) {
      errorMsg = errors?.phone?.countryCode
    } else if (touched?.phone?.areaCode && errors?.phone?.areaCode) {
      errorMsg = errors?.phone?.areaCode;
    } else if (touched?.phone?.cli && errors?.phone?.cli) {
      errorMsg = errors?.phone?.cli;
    }
    return errorMsg;
  }

  const getMobileInputError = (touched: any, errors: any) => {
    let errorMsg;
    if (touched.mobile?.countryCode && errors?.mobile?.countryCode) {
      errorMsg = errors?.mobile?.countryCode
    } else if (touched?.mobile?.areaCode && errors?.mobile?.areaCode) {
      errorMsg = errors?.mobile?.areaCode;
    } else if (touched?.mobile?.cli && errors?.mobile?.cli) {
      errorMsg = errors?.mobile?.cli;
    }
    return errorMsg;
  }

  const getCustomPhoneInputError = (touched: any, errors: any) => {
    let errorMsg;

    if (customItemName) {
      if (errors?.[customItemName]?.countryCode) {
        errorMsg = errors?.[customItemName]?.countryCode
      } else if (errors?.[customItemName]?.areaCode) {
        errorMsg = errors?.[customItemName]?.areaCode;
      } else if (errors?.[customItemName]?.cli) {
        errorMsg = errors?.[customItemName]?.cli;
      }
      return errorMsg;
    }
  }

  const render = () => {

    if (customItemName) {

      return <div
        className={cs('phoneInputWithLabel', className, {
          ['isDisabled']: props.disabled,
        })}
      >
        <label
          htmlFor={props.id}
          className={cs(labelClassName, { ['required']: !!required }, `label label-le--${theme}`)}
        >
          {label}
        </label>
        <div className={"phoneInputGrid"}>
          {fields.map((field: any) =>
            <input
              id={field.id}
              name={field.name}
              placeholder={field.placeholder}
              type="text"
              value={field?.value?.trimStart()}
              className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value, ['error']: touched?.[customItemName] && errors?.[customItemName], [`error-le--${theme}`]: touched?.[customItemName] && errors?.[customItemName] }, `input-le--${theme}`)}
              onChange={onChange}
              onPaste={onPaste}
              {...props}
            />
          )}
        </div>
        {(touched?.[customItemName]?.countryCode || touched?.[customItemName]?.areaCode || touched?.[customItemName]?.cli) && <InputError errorMessage={getCustomPhoneInputError(touched, errors)}/>}
      </div>
    }

    switch (phoneType) {
      case 1:
        return <div
          className={cs('phoneInputWithLabel', className, {
            ['isDisabled']: props.disabled,
          })}
        >
          <label
            htmlFor={props.id}
            className={cs(labelClassName, { ['required']: !!required }, `label label-le--${theme}`)}
          >
            {label}
          </label>
          {fields.map((field: any) =>
            <input
              id={field.id}
              name={field.name}
              placeholder={field.placeholder}
              type="text"
              value={field?.value?.trimStart()}
              className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value, ['error']: touched?.phone && errors?.phone, [`error-le--${theme}`]: touched?.phone && errors?.phone }, `input-le--${theme}`)}
              onChange={onChange}
              onPaste={onPaste}
              {...props}
            />
          )}
          {(touched?.phone?.countryCode || touched?.phone?.areaCode || touched?.phone?.cli) && <InputError errorMessage={getPhoneInputError(touched, errors)}/>}
        </div>
        break;
      case 2:
        return <div
          className={cs('phoneInputWithLabel', className, {
            ['isDisabled']: props.disabled,
          })}
        >
          <label
            htmlFor={props.id}
            className={cs(labelClassName, { ['required']: !!required }, `label label-le--${theme}`)}
          >
            {label}
          </label>
          {fields.map((field: any) =>
            <input
              id={field.id}
              name={field.name}
              placeholder={field.placeholder}
              type="text"
              value={field?.value?.trimStart()}
              className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value, ['error']: touched?.mobile && errors?.mobile, [`error-le--${theme}`]: touched?.mobile && errors?.mobile }, `input-le--${theme}`)}
              onChange={onChange}
              onPaste={onPaste}
              {...props}
            />
          )}
          {(touched?.mobile?.countryCode || touched?.mobile?.areaCode || touched?.mobile?.cli) && <InputError errorMessage={getMobileInputError(touched, errors)}/>}
        </div>
        break;
      default:
        return <div
          className={cs('phoneInputWithLabel', className, {
            ['isDisabled']: props.disabled,
          })}
        >
          <label
            htmlFor={props.id}
            className={cs(labelClassName, { ['required']: !!required }, `label label-le--${theme}`)}
          >
            {label}
          </label>
          {fields.map((field: any) =>
            <input
              id={field.id}
              name={field.name}
              placeholder={field.placeholder}
              type="text"
              value={field?.value?.trimStart()}
              className={cs('input', inputClassName, { ['isNotEmpty']: !!props.value, ['error']: touched?.phone && errors?.phone, [`error-le--${theme}`]: touched?.phone && errors?.phone }, `input-le--${theme}`)}
              onChange={onChange}
              onPaste={onPaste}
              {...props}
            />
          )}
          {(touched?.phone?.countryCode || touched?.phone?.areaCode || touched?.phone?.cli) && <InputError errorMessage={getPhoneInputError(touched, errors)}/>}
        </div>
        break;
    }
  }

  return(
    <>
      {render()}
    </>
  )
}

