import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { saveCurrentStep, saveStep1Data } from "../../../../redux/actions/step.action";
import { useCurrentStep, useSelectCountries, useSelectError, useSelectLang, useSelectLanguage, useSelectLegalForms, useSelectPartners, useSelectSalutations, useSelectUser, useStep1Data, } from "../../../../redux/store";
import { trimStringProperties, useTranslations } from "../../../../utils/helper/utils";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { NfoCustomerWrapperComponent } from "../wrapper/newCustomerWrapper.component";
import "./step.scss";
import { MainDataForm } from "./forms/MainDataForm";
import { RegisteredAddressForm } from "./forms/RegisteredAddressForm";
import { useFormik } from "formik";
import { areaCodeRequiredCheck, cliRequiredCheck, countryCodeRequiredCheck, createValidatorSchema, emailCheckReq, MaxLengthCheck, requiredCheck } from "../../../../utils/validation";
import { CpxFormikForm } from "../../../../../core/components/formikForm.component";
import { apiCallAction } from "../../../../redux/actions/apiCall.action";
import { ACTION_CONST } from "../../../../constants/action.constants";
import { selectCurrentLanguage } from "../../../../../core/uiLanguage/lang.slice";
import { Alert } from "../../../common/Alert/Alert.component";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { clearError } from "../../../../redux/actions/error.action";
import { COUNTRY_IDS, ID_ADDRESSES, ID_SALUTATIONS, INPUT_MAX_LENGTHS } from "../../../../constants/configs/config.constants";
import { NfoGenerateFakeDataComponent } from "../generateFakeData/generateFakeData.component";
import { useTheme } from "../../../../../core/utility/themeContext";
import { InfoWarningBox } from "../wrapper/infoWarningBox/infoWarningBox.component";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;


export const NfoBasicDataStep = () => {
  const translation = useTranslations(TRANSLATIONS.stepper.newCustomer);
  const dispatch: AppDispatch = useDispatch();
  const currentStep = useCurrentStep();
  const dataOfStep1 = useStep1Data();
  const ErrorsOfBE = useSelectError();
  const legalForms = useSelectLegalForms();
  const salutations = useSelectSalutations();
  const languages = useSelectLanguage();
  const languageOfPartner = useSelectPartners()[0]?.language;
  const legalEntity = useSelectUser()?.legalEntity;
  const currentLang = selectCurrentLanguage(useSelectLang());
  const countries = useSelectCountries();
  const theme = useTheme();
  const [mainAddressSelectedCountry, setMainAddressSelectedCountry] = useState<number>(+dataOfStep1?.mainAddress?.country?.id || NaN);
  useEffect(() => {
    setMainAddressSelectedCountry(+dataOfStep1?.mainAddress?.country?.id);
  }, [dataOfStep1])

  const salutationOptions = Array.isArray(salutations) ? salutations.map(salutation => {
    return { name: salutation.description, id: salutation.id }
  }) : [];

  const [selectedSalutation, setSelectedSalutation] = useState<number>(+dataOfStep1?.customer?.salutation?.id || salutationOptions[2]?.id);

  const legalFormOptions = Array.isArray(legalForms) ? legalForms.map(legalForm => {
    return { name: legalForm.description, id: legalForm.id }
  }) : [];

  let contactLanguageOptions = Array.isArray(languages) ? languages.map(language => {
    return { name: language.description, id: language.id }
  }) : [];

  if (theme === 'dts') {
    contactLanguageOptions = contactLanguageOptions.filter((lang) => lang.id === 1)
  }

  const contactLanguageOfPartner = Array.isArray(languages) ?
    languages.filter((lang: any) => lang?.id === languageOfPartner?.id).map(lang => {
      return { name: lang.description, id: lang.id }
    }) : [];

  const countryOptions = Array.isArray(countries) ? countries.map(country => {
    return { name: country.description, id: country.id }
  }) : [];


  useEffect(() => {
    dispatch(saveCurrentStep(1));
  }, []);

  useEffect(() => {
    dispatch(apiCallAction(ACTION_CONST.API_GET_LEGAL_FORMS));
    dispatch(apiCallAction(ACTION_CONST.API_GET_SALUTATIONS));
    dispatch(apiCallAction(ACTION_CONST.API_GET_LANGUAGES));
  }, [currentLang]);

  const goForward = () => {
    dispatch(saveCurrentStep(currentStep + 1));
  }

  const requiredMsg = translation.fieldRequired();


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      /* MainDataForm */
      customer: {
        legalForm: {
          id: dataOfStep1?.customer?.legalForm?.id || legalFormOptions[-1]?.id,
        },
        salutation: {
          id: dataOfStep1?.customer?.salutation?.id || salutationOptions[2]?.id,
        },
        language: {
          id: dataOfStep1?.customer?.language?.id || contactLanguageOfPartner[-1]?.id,
        },
        companyName1: dataOfStep1?.customer?.companyName1 || '',
        companyName2: dataOfStep1?.customer?.companyName2 || '',
        taxIdentificationNumber: dataOfStep1?.customer?.taxIdentificationNumber || '',
        email: dataOfStep1?.customer?.email || '',
        companyNumber: dataOfStep1?.customer?.companyNumber || '',
        phone: {
          countryCode: dataOfStep1?.customer?.phone?.countryCode || '',
          areaCode: dataOfStep1?.customer?.phone?.areaCode || '',
          cli: dataOfStep1?.customer?.phone?.cli || '',
          phoneType: {
            id: 2
          },
        },
      },
      /* RegisteredAddressForm */
      mainAddress: {
        country: {
          id: dataOfStep1?.mainAddress?.country?.id || countryOptions[-1]?.id
        },
        street: dataOfStep1?.mainAddress?.street || '',
        houseNumber: dataOfStep1?.mainAddress?.houseNumber || '',
        additionalAddress: dataOfStep1?.mainAddress?.additionalAddress || '',
        zip: dataOfStep1?.mainAddress?.zip || '',
        county: dataOfStep1?.mainAddress?.county || '',
        city: dataOfStep1?.mainAddress?.city || '',
        city2: dataOfStep1?.mainAddress?.city2 || '',
        city3: dataOfStep1?.mainAddress?.city3 || '',
        district: dataOfStep1?.mainAddress?.district || '',
        province: dataOfStep1?.mainAddress?.province || '',
        addressType: {
          id: ID_ADDRESSES.MAIN,
        },
      },
    },
    validationSchema: createValidatorSchema({
      customer: createValidatorSchema({
        companyName1: MaxLengthCheck(selectedSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.firstName : INPUT_MAX_LENGTHS.customerData.companyName1, requiredCheck(requiredMsg)),
        companyName2: MaxLengthCheck(selectedSalutation !== ID_SALUTATIONS.COMPANY ? INPUT_MAX_LENGTHS.customerData.lastName : INPUT_MAX_LENGTHS.customerData.companyName2, selectedSalutation !== ID_SALUTATIONS.COMPANY && requiredCheck(requiredMsg)),
        taxIdentificationNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.account.taxIdentificationNumber),
        phone: createValidatorSchema({
          countryCode: countryCodeRequiredCheck(translation.countryCodeRequired(), translation.countryCodeSyntax()),
          areaCode: areaCodeRequiredCheck(translation.areaCodeRequired(), translation.areaCodeSyntax()),
          cli: cliRequiredCheck(translation.cliRequired(), translation.cliSyntax()),
        }),
        legalForm: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        salutation: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        language: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        }),
        companyNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.customerData.companyNumber, legalEntity?.id === 4 && requiredCheck(requiredMsg)),
        email: MaxLengthCheck(INPUT_MAX_LENGTHS.contact.email, emailCheckReq(requiredMsg, translation.emailSyntax())),
        /* RegisteredAddressForm */
      }),
      mainAddress: createValidatorSchema({
        street: MaxLengthCheck(INPUT_MAX_LENGTHS.address.street, requiredCheck(requiredMsg)),
        houseNumber: MaxLengthCheck(INPUT_MAX_LENGTHS.address.houseNumber,
          (mainAddressSelectedCountry === COUNTRY_IDS.DE ||
            mainAddressSelectedCountry === COUNTRY_IDS.AT ||
            mainAddressSelectedCountry === COUNTRY_IDS.FR ||
            mainAddressSelectedCountry === COUNTRY_IDS.IT ||
            mainAddressSelectedCountry === COUNTRY_IDS.PL ||
            mainAddressSelectedCountry === COUNTRY_IDS.CH ||
            mainAddressSelectedCountry === COUNTRY_IDS.ES ||
            mainAddressSelectedCountry === COUNTRY_IDS.GB) && requiredCheck(requiredMsg)),
        zip: MaxLengthCheck(INPUT_MAX_LENGTHS.address.zip, requiredCheck(requiredMsg)),
        city: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city, requiredCheck(requiredMsg)),
        city2: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city2, mainAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
        city3: MaxLengthCheck(INPUT_MAX_LENGTHS.address.city3, mainAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
        district: MaxLengthCheck(INPUT_MAX_LENGTHS.address.district, mainAddressSelectedCountry === COUNTRY_IDS.PL && requiredCheck(requiredMsg)),
        additionalAddress: MaxLengthCheck(INPUT_MAX_LENGTHS.address.additionalAddress),
        province: MaxLengthCheck(INPUT_MAX_LENGTHS.address.province, mainAddressSelectedCountry === COUNTRY_IDS.ES && requiredCheck(requiredMsg)),
        county: MaxLengthCheck(INPUT_MAX_LENGTHS.address.county),
        country: createValidatorSchema({
          id: requiredCheck(requiredMsg),
        })
      }),
    }),
    onSubmit: async (values: any) => {

      const finalValues = {
        ...dataOfStep1,
        ...values,
        stepper: "CUSTOMER_DATA"
      }
      const tempValues = {
        ...values,
        stepper: "CUSTOMER_DATA"
      }
      trimStringProperties(finalValues);
      trimStringProperties(tempValues);

      dispatch(apiCallAction(ACTION_CONST.API_VALIDATE_CUSTOMER_DATA, tempValues))
       .then(() => {
          dispatch(clearError())
          dispatch(saveStep1Data(finalValues));
          goForward();
       })
       .catch(() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
    }

  });


  const handleSalutationDropdownChange = (e: any) => {
    formik.handleChange(e);
    setSalutation(+e.target.value);

  }

  const setSalutation = (val: any) => {
    setSelectedSalutation(val);
  }

  return (
    <>
      <NfoCustomerWrapperComponent
        title={translation.basicData()}
        subtitle={translation.customerDataSubTitle()}
      >
        <>
        <InfoWarningBox
          text={translation.documentInfoMessage()}
        />
        <CpxFormikForm handleSubmit={formik.handleSubmit}
                       initialValues={formik.initialValues}
                       id={'new-customer-form'}
        >
          <NfoGenerateFakeDataComponent salutationOptions={salutationOptions} countryOptions={countryOptions} setSalutation={setSalutation}/>
          <MainDataForm values={formik.values} handleChange={formik.handleChange} touched={formik.touched}
                        errors={formik.errors}
                        salutationOptions={salutationOptions} legalFormOptions={legalFormOptions}
                        contactLanguageOptions={contactLanguageOptions} legalEntity={legalEntity} handleSalutation={handleSalutationDropdownChange}
          />
          <RegisteredAddressForm values={formik.values} handleChange={formik.handleChange}
                                 touched={formik.touched} errors={formik.errors}
                                 countryOptions={countryOptions}
                                 selectedCountry={mainAddressSelectedCountry}
                                 setSelectedCountry={setMainAddressSelectedCountry}
          />
        </CpxFormikForm>
        {ErrorsOfBE.errorData && ErrorsOfBE.errorData.length > 0 &&
          ErrorsOfBE?.requestData?.currentData?.stepper === "CUSTOMER_DATA" && <Alert errors={ErrorsOfBE.errorData}/>}
      </>
      </NfoCustomerWrapperComponent>
    </>
  );
};
